import React, { useState } from "react";
import text from '../../util/text';
import { InputGroup, Form } from "react-bootstrap";
import CommonApi from '../../api/commonApi'
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  let api = new CommonApi();
  let navigate = useNavigate()
	const cookies = new Cookies();
	let cookieData = cookies.get('userInfo');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)

  const validate = () => {
    let formErrors = {};
    if (!newPassword) formErrors.newPassword = "New Password is required";
    if (!confirmPassword) formErrors.confirmPassword = "Confirm Password is required";
    return formErrors;
  };

  const handlePasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrors({ ...errors, newPassword: '' }); // Clear error when typing
  };

  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setConfirmPassword(value);
    if (newPassword !== value) {
      setErrors({
        ...errors,
        confirmPassword: "Passwords do not match",
      });
    } else {
      setErrors({
        ...errors,
        confirmPassword: "",
      });
    }
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      setSubmitBtnStatus(true)
      let sendObj = {
        _id : cookieData._id,
        password : newPassword,
      }
      api.changePassword(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
          navigate("/manageBanks");
          setSubmitBtnStatus(false)
        } else {
          toast.error(res.message);
          setErrors({});
          setSubmitBtnStatus(false)
        }
      }).catch((err) => {
        console.error("Change Password error => ", err);
        setErrors({});
        setSubmitBtnStatus(false)
      });
    }
  };


  return (
    <>
      <div className="min-height-200px mb-20 profile-section">
        <div className="p-2">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4 className="text-black h3 mb-3">
                  {text.changePasswordHeader}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="px-3 py-5 card-box height-100-p">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mb-30 bg-white">
              <div className="profile-tab height-100-p">
                <div className="tab height-100-p">
                  <div className="height-100-p">
                    <div className="profile-setting">
                      <form onSubmit={handleSubmit}>
                        <ul className="row">
                          <li className="weight-500 col-md-12">
                            <div className="form-group">
                              <label>New Password</label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  className="form-control form-control-lg"
                                  type={!showPassword ? "password" : "text"}
                                  value={newPassword}
                                  onChange={handlePasswordChange}
                                />
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="group-input-button input-group-text cursor"
                                >
                                  {!showPassword ? (
                                    <i
                                      className="icon-copy fa fa-eye customization2"
                                      onClick={() => setShowPassword(true)}
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <i
                                      className="icon-copy fa fa-eye-slash customization2"
                                      onClick={() => setShowPassword(false)}
                                      aria-hidden="true"
                                    />
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.newPassword && (
                                <div className="text-danger">{errors.newPassword}</div>
                              )}
                            </div>
                            <div className="form-group">
                              <label>Confirm Password</label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  className="form-control form-control-lg"
                                  type={!showConfirmPassword ? "password" : "text"}

                                  value={confirmPassword}
                                  onChange={handleConfirmPasswordChange}
                                />
                                <InputGroup.Text
                                  id="basic-addon1"
                                  className="group-input-button input-group-text cursor"
                                >
                                  {!showConfirmPassword ? (
                                    <i
                                      className="icon-copy fa fa-eye customization2"
                                      onClick={() => setShowConfirmPassword(true)}
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <i
                                      className="icon-copy fa fa-eye-slash customization2"
                                      onClick={() => setShowConfirmPassword(false)}
                                      aria-hidden="true"
                                    />
                                  )}
                                </InputGroup.Text>
                              </InputGroup>
                              {errors.confirmPassword && (
                                <div className="text-danger">{errors.confirmPassword}</div>
                              )}
                            </div>
                            <div className="form-group mb-0 d-flex align-item-left">
                              <input
                                type="submit"
                                value={text.save}
                                className="btn btn-orange"
                                disabled={submitBtnStatus}
                              />
                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
