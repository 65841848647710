import React from "react";
import { Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from "../pages/master/dashboard";
import Profile from "../pages/master/profile";
import AddManager from "../pages/master/ManageTeamMember/AddTeamMember";
import ManageAxeTool from "../pages/master/ManageAxeTool/index";
import ManageFeedbacks from "../pages/master/ManageFeedbacks";
import ManageColleagueFeedbacks from "../pages/master/ManageColleagueFeedbacks";
import AddAxe from "../pages/master/ManageAxe/AddAxe";
import ManageAxes from "../pages/master/ManageAxe";
import ManageTeamMember from "../pages/master/ManageTeamMember";
import Header from "../layouts/header";
import AutoLogout from "../pages/auth/logout";
import ChangePassword from "../pages/master/ChangePassword"
import Notifications from "../pages/master/Notifications/index";
import ManageSharedFeedback from "../pages/master/ManageSharedFeedback/index";

const traderRouter = () => {
  return (
    <>
      <div className="main">
        <div className="main-container" style={{ height: 'inherit' }}>
          <Header />
          <div className="pd-ltr-20 xs-pd-20-10" style={{ height: '100%', overflow: 'auto' }}>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />

              {/* magage axe */}
              <Route path="/addAxe/:id" element={<AddAxe />} />
              <Route path="/addAxe" element={<AddAxe />} />
              <Route path="/manageAxe" element={<ManageAxes />} />

              {/* magage Colleague’s feedback */}
              <Route path="/manage-colleague-feedback" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/primary" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/axes" element={<ManageColleagueFeedbacks />} />
              <Route path="/manage-colleague-feedback/general" element={<ManageColleagueFeedbacks />} />

              {/* magage feedback */}
              {/* <Route path="/manageFeedback" element={<ManageFeedbacks />} /> */}
              <Route path="/manageFeedback" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/primary" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/axes" element={<ManageFeedbacks />} />
              <Route path="/manageFeedback/general" element={<ManageFeedbacks />} />
              {/* <Route index element={<Navigate to="/manageFeedback/primary" />} /> */}
              {/*  <Route path="primary" element={<PrimaryFeedback />} />
                <Route path="axes" element={<AxesFeedback />} />
                <Route path="general" element={<GeneralFeedback />} />
              </Route> */}
              {/*  <Route path="/addFeedback/:id" element={<AddFeedback />} />
              <Route path="/addFeedback" element={<AddFeedback />} />
              <Route path="/manageFeedback" element={<ManageFeedbacks />} />

              {/* Manager TeamMember */}
              <Route path="/addManager/:id" element={<AddManager />} />
              <Route path="/addManager" element={<AddManager />} />
              <Route path="/manageTeamMember/:bankId" element={<ManageTeamMember />} />

              {/* magage profile */}
              <Route path="/profile" element={<Profile />} />

              {/* Manage Axe Tool */}
              <Route path="/manageAxeTool" element={<ManageAxeTool />} />

              {/* manage shared feedback */}
              <Route path="/manageSharedFeedback" element={<ManageSharedFeedback />} />

              {/* notifications */}
              <Route path="/notifications" element={<Notifications />} />

              {/* change password */}
              <Route path="/changePassword" element={<ChangePassword />} />

              {/* logout */}
              <Route path="/logout" element={<AutoLogout />} />
              <Route path="/*" element={<Navigate to={`/manageAxe`} />} />
            </Routes>
          </div>
        </div>

      </div>
    </>
  )
}
export default traderRouter;
