import React, { useContext, useState } from "react";
import {  useLocation } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import { useEffect } from "react";
import FeedbackApi from "../../../api/feedbackApi";
import { ShareFeedbackModal } from "../../../components/Modal/ShareFeedbackModal";
import text from '../../../util/text';
import { Cookies } from "react-cookie";
import GlobalContext from "../../../context/global/globalContext";
import { sidebarModule, sideTypeMap } from '../../../util/constants'


const Index = () => {

  const location = useLocation();
  const globalContext = useContext(GlobalContext);
  const api = new FeedbackApi();
  const [type, setType] = useState(null);
  const [shareFeedbackData, setShareFeedbackData] = useState([]);
  const [tableInstance, setTableInstance] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [showShareFeedbackModal, setShowShareFeedbackModal] = useState(false);
  const [singleFeedbackId, setSingleFeedbackId] = useState(null);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');

  const bankColumns = [
    {
      header: () => <div style={{ textAlign: "center" }}>Shared with</div>,
      accessorKey: "shareWith",
      headerClassName: "text-center",
      id: "shareWith",
      cell: ({ row }) => (
        <span className='d-flex justify-content-center'>
          <div className="cursor" data-toggle="modal" data-target="#alert-modal34"
            onClick={() => {
              setShowShareFeedbackModal(true);
              setSingleFeedbackId(row.original);
            }}>
              
              <span className="circlewith">
              {/* <i className="icon-copy fa fa-list" aria-hidden="true"></i> */}
              <i className="icon-copy fa fa-list" aria-hidden="true"></i>
              </span>
          </div>
        </span>
      ),
    },
    /* {
      header: "Axe Type",
      accessorKey: "axeType",
      id: "axeType",
      headerClassName: "text-center",
      // cell: (props) => {
      //   // return <DropdownDailyAndAxe {...props} />;
      // },
      enableSorting: false,
      enableColumnFilter: true,
    }, */
    {
      //header: "Type",
      header: () => <div style={{ textAlign: "center" }}>Type</div>,
      accessorKey: "feedbackType",
      id: "feedbackType",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.feedbackType}
          </div>
        );
      },
      enableSorting: false,
      enableColumnFilter: true,
      size: 120,
    },
    {
      // header: "Title",
      header: () => <div style={{ textAlign: "center" }}>Title</div>,
      accessorKey: "title",
      id: "title",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.title}
          </div>
        );
      },
    },
    {
      // header: "Client",
      header: () => <div style={{ textAlign: "center" }}>Client</div>,
      accessorKey: "client",
      id: "client",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.client}
          </div>
        );
      },
    },
    {
      //header: "Bond",
      header: () => <div style={{ textAlign: "center" }}>Bond</div>,
      accessorKey: "bond",
      id: "bond",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.bond}
          </div>
        );
      },
    },
    {
      // header: "Sector",
      header: () => <div style={{ textAlign: "center" }}>Sector</div>,
      accessorKey: "sector",
      id: "sector",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.sector}
          </div>
        );
      },
    },
    {
      // header: "Maturity",
      header: () => <div style={{ textAlign: "center" }}>Maturity</div>,
      accessorKey: "maturity",
      id: "maturity",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.maturity}
          </div>
        );
      },
    },
    {
      header: () => <div style={{ textAlign: "center" }}>Side</div>,
      accessorKey: "direction",
      enableSorting: false,
      enableColumnFilter: true,
      id: "direction",
      minSize: 155,
      cell: ({ row }) => {
        const sideValue = row.original.direction;
        const mappedSide = sideTypeMap[sideValue] || "Unknown"; // Default to "Unknown" if not mapped
        return (
          <div className="d-flex justify-content-center">
            {mappedSide}
          </div>
        );
      },
    },
    {
      //header: "Interest",
      header: () => <div style={{ textAlign: "center" }}>Interest</div>,
      accessorKey: "interest",
      id: "interest",
      size: 100,
      minSize: 100,
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.interest}
          </div>
        );
      },
    },
    {
      //header: "Client's Target Level",
      header: () => <div style={{ textAlign: "center" }}>Client's Target Level</div>,
      accessorKey: "clientsTargetLevel",
      id: "clientsTargetLevel",
      size: 200,
      minSize: 100,
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.clientsTargetLevel}
          </div>
        );
      },
    },
    {
      //header: "Client's Amount",
      header: () => <div style={{ textAlign: "center" }}>Client's Amount</div>,
      accessorKey: "clientsAmount",
      id: "clientsAmount",
      size: 180,
      minSize: 100,
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.clientsAmount}
          </div>
        );
      },
    },
    {
      //header: "Comment",
      header: () => <div style={{ textAlign: "center" }}>Comment</div>,
      accessorKey: "comments",
      cell: ({ row }) => {
        return (
          <div className="d-flex justify-content-center">
            {row.original.comments}
          </div>
        );
      },
      id: "comments",
    },
  ];

  const shareFeedbackList = async () => {

    try {
      setIsLoading(true);
      let sendObj = {
        associatedWith: cookieData.AssociatedWith,
        userId: cookieData._id,
      };
      const res = await api.shareFeedback(sendObj);
      if (res.success) {
        setShareFeedbackData(res.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Getting feedbacks error => ", err);
      setIsLoading(false);
    }
  }

  const initializeColumnVisibility = () => {
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);

    // setColumnVisibility1(initialVisibility);
    // setCheckboxState1(initialVisibility);
    try {
      let key = sidebarModule[1]
      let data = {
        ...JSON.parse(localStorage.getItem('columnVisibility')),
        [key]: {
          dailyTopAxes: {},
          axes: {}
        }
      }
      data[key]['dailyTopAxes'] = initialVisibility
      data[key]['axes'] = initialVisibility
      const serializedVisibility = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedVisibility);
    } catch (error) {
      console.error('Error serializing column visibility:', error);
    }
  };


  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility.axeTool) {
      if (storedVisibility?.axeTool?.dailyTopAxes) {
        data = storedVisibility.axeTool.dailyTopAxes
        setColumnVisibility(data);
        setCheckboxState(data);
      }
      // if (storedVisibility?.axeTool?.axes) {
      //   data = storedVisibility.axeTool.axes
      //   setColumnVisibility1(data);
      //   setCheckboxState1(data);
      // }
    } else {
      initializeColumnVisibility();
    }
  }, []);


  // Update lastSegment based on the URL path segments
  // useEffect(() => {
  //   const pathSegments = location.pathname.split("/");
  //   const newLastSegment = pathSegments[pathSegments.length - 1];
  //   //setType(newLastSegment);
  //   setType('manageFeedback');
    
  // }, [location.pathname]);
  

  useEffect(() => {
    shareFeedbackList();
  }, []);

  return (
    <>
       {showShareFeedbackModal && (
        <ShareFeedbackModal
          setShowShareFeedbackModal={setShowShareFeedbackModal}
          selectedList={singleFeedbackId}
          tableInstance={tableInstance}
        />
      )}
   
      <div className="mb-10">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h4 className="text-black h3 mb-3">{text.shareFeedbackTitle}</h4>
          </div>
          {/*  <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 mb-sm-3">
              <div className="d-flex justify-content-between justify-content-md-end justify-content-md-end">
                
                <>
                  <i
                    className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                    aria-hidden="true"
                    onClick={handlePopoverClick}
                  ></i>
                  <Overlay
                    show={showPopover}
                    target={target}
                    placement="bottom"
                    containerPadding={20}
                    className="setOverlay"
                    ref={popoverRef}
                  >
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div className="inline-block  rounded">
                          <div className="d-flex align-items-center p-1 left-margin">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="10 0 512 512"
                              width="20"
                              height="20"
                            >
                              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                            </svg>
                            <span className="ml-2">
                              <b>{text.filterColumn}</b>{" "}
                            </span>
                          </div>
                          <div className="row p-2">
                            {tableInstance &&
                              tableInstance
                                .getAllLeafColumns()
                                .filter(
                                  (column) =>
                                    column.id !== "action" &&
                                    column.id !== "select"
                                )
                                .map((column) => {
                                  return (
                                    <div key={column.id} className="col-6 px-1">
                                      <label className="cursor">
                                        <input
                                          {...{
                                            type: "checkbox",
                                            checked:
                                              checkboxState[column.id] !==
                                                undefined
                                                ? checkboxState[column.id]
                                                : column.getIsVisible(),
                                            onChange:
                                              combinedOnChangeHandler(column,),
                                          }}
                                        />{" "}
                                        {column.columnDef.header}
                                      </label>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
                
                <div className="d-flex justify-content-end align-items-md-center">
                  <input
                    type="button"
                    value={text.addToFeedback}
                    className="btn btn-orange"
                    onClick={() => addToFeedback('DailyTopAxes')}
                    disabled={addToFeedbackBtnStatusDaily}
                  />
                </div>
              </div>
            </div>
          </div> */}
        </div>


        {/* for Daily Top axes */}
        <div className="card-box">
          <DataTable 
            data={shareFeedbackData} 
            columns={bankColumns} 
            setTableInstance={setTableInstance} 
            isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
          />
        </div>
      </div>
    </>
  )
}

export default Index