import React, { useContext, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import { useEffect } from "react";
//import BankApi from "../../../api/bankApi";
import AxeApi from "../../../api/axeApi";
import { toast } from "react-toastify";
import { Popover, Overlay } from "react-bootstrap";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import text from '../../../util/text';
import { Loader } from "../../../components/Loader";
import IndeterminateCheckbox from "../../../components/DataTable/IndeterminateCheckbox";
import { Cookies } from "react-cookie";
import GlobalContext from "../../../context/global/globalContext";
import { sidebarModule, timeZoneChange } from '../../../util/constants'

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const api = new AxeApi();
  const navigate = useNavigate();
  const [dailyTopAxes, setDailyTopAxes] = useState([]);
  const [axes, setaxes] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [showPopover1, setShowPopover1] = useState(false);
  const [target, setTarget] = useState(null);
  const [target1, setTarget1] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnVisibility1, setColumnVisibility1] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [tableInstance2, setTableInstance2] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [isLoading, setIsLoading] = useState(false);
  const popoverRef = useRef(null);
  const popoverRef1 = useRef(null);
  const [checkboxState, setCheckboxState] = useState({});
  const [checkboxState1, setCheckboxState1] = useState({});
  const [addToFeedbackBtnStatusDaily, setAddToFeedbackBtnStatusDaily] = useState(false)
  const [addToFeedbackBtnStatusAxes, setAddToFeedbackBtnStatusAxes] = useState(false)
  useEffect(() => {
    getfeedbacks();
  }, [])

  // for daily top axes
  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPopover]);

  // for Axes
  const handlePopoverClick1 = (event) => {
    setTarget1(event.target);
    setShowPopover1(!showPopover1);
  };

  const handleClickOutside1 = (event) => {
    if (popoverRef1.current && !popoverRef1.current.contains(event.target)) {
      setShowPopover1(false);
    }
  };

  useEffect(() => {
    if (showPopover1) {
      document.addEventListener("mousedown", handleClickOutside1);
    } else {
      document.removeEventListener("mousedown", handleClickOutside1);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside1);
    };
  }, [showPopover1]);

  const getfeedbacks = async () => {
    setIsLoading(true)
    let sendObj = {
      associatedWith: cookieData.AssociatedWith,
      currentUserId: cookieData._id
    };
    api.getAxes(sendObj).then((res) => {
      if (res.success) {
        let dailyTopAxes = res.data.filter(item => item.axeType == 'DailyTopAxes')
        let axes = res.data.filter(item => item.axeType == 'Axes')
        setDailyTopAxes(dailyTopAxes);
        setaxes(axes);
        setIsLoading(false)
      }
    }).catch((err) => {
      setIsLoading(false)
      console.error("Getting Axes error => ", err);
    });
  }

  async function handleDuplicateRow(row) {
    if (!row || !row.original) {
      console.error("Invalid row data");
      return;
    }

    try {
      const res = await api.addEditAxe({
        id: row.original._id, // ID of the row to be duplicated
        isDuplicate: true, // Indicate this is a duplication request
        currentUserId: cookieData._id, // current User
      });

      console.log("res=================", res);


      if (res.success) {
        // Prepend the duplicated row to the table data
        //setDailyTopAxes((prevData) => [res.data, ...prevData]);
        getfeedbacks();
        setDailyTopAxes();
        toast.success("Row duplicated successfully!");
      } else {
        console.error("Error duplicating row:", res.message);
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error duplicating row:", error);
      toast.error("Something went wrong while duplicating the row.");
    }
  }


  const dailyTopAxesData = useMemo(() => dailyTopAxes, [dailyTopAxes])
  const axesData = useMemo(() => axes, [axes]);

  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility[cookieData._id]?.axeTool) {
      if (storedVisibility[cookieData._id]?.axeTool?.dailyTopAxes) {
        data = storedVisibility[cookieData._id].axeTool.dailyTopAxes;
        const updatedData = synchronizeColumnData(data, bankColumns);
        console.log('updatedData dailyTopAxes', updatedData);

        setColumnVisibility(updatedData);
        setCheckboxState(updatedData);

        // save updated data back to localStorage
        const storedData = JSON.parse(localStorage.getItem('columnVisibility')) || {};
        storedData[cookieData._id].axeTool.dailyTopAxes = updatedData;
        localStorage.setItem('columnVisibility', JSON.stringify(storedData));
      }
      if (storedVisibility[cookieData._id]?.axeTool?.axes) {
        data = storedVisibility[cookieData._id].axeTool.axes;
        const updatedData = synchronizeColumnData(data, bankColumns);
        console.log('updatedData axeTool', updatedData);
        setColumnVisibility1(updatedData);
        setCheckboxState1(updatedData);
        // save updated data back to localStorage
        const storedData = JSON.parse(localStorage.getItem('columnVisibility')) || {};
        storedData[cookieData._id].axeTool.axes = updatedData;
        localStorage.setItem('columnVisibility', JSON.stringify(storedData));
      }
    } else {
      initializeColumnVisibility();
    }
  }, []);

  const synchronizeColumnData = (data, bankColumns) => {
    const columnIds = bankColumns.map((column) => column.id);
    const updatedVisibility = columnIds.reduce((acc, columnId) => {
      acc[columnId] = data[columnId] ?? true;
      return acc;
    }, {});

    return updatedVisibility;
  };

  const initializeColumnVisibility = () => {
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true;
      return acc;
    }, {});
    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);

    setColumnVisibility1(initialVisibility);
    setCheckboxState1(initialVisibility);
    try {
      let key = sidebarModule[1]
      let existingData = JSON.parse(localStorage.getItem('columnVisibility')) || {};
      let data = {
        ...existingData,
        [cookieData._id]: {
          ...(existingData[cookieData._id] || {}),
          [key]: {
            dailyTopAxes: {},
            axes: {}
          }
        }
      }
      data[cookieData._id][key]['dailyTopAxes'] = initialVisibility
      data[cookieData._id][key]['axes'] = initialVisibility
      const serializedVisibility = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedVisibility);
    } catch (error) {
      console.error('Error serializing column visibility:', error);
    }
  };

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };

      let key = sidebarModule[1]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [cookieData._id]: {
          [key]: {
            dailyTopAxes: {},
            axes: {}
          },
        }
      };
      data[cookieData._id][key]['dailyTopAxes'][column.id] = event.target.checked;

      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  };

  const combinedOnChangeHandler1 = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState1((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };

      let key = sidebarModule[1]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [cookieData._id]: {
          [key]: {
            dailyTopAxes: {},
            axes: {}
          },
        }
      };
      data[cookieData._id][key]['axes'][column.id] = event.target.checked;
      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  };

  const copyRowValue = (row) => {
    const { direction, currency, size, coupon, ticker, maturity, govSpread, zSpread, CashPrice, isin } = row.original;

    const sideTypeMap = {
      weBuy: 'We buy',
      weSell: 'We sell',
      clientBuys: 'Client buys',
      clientSells: 'Client sells'
    };

    const formattedDirection = sideTypeMap[direction] || direction;

    const formattedValue = `${formattedDirection} ${currency} ${size} ${coupon} ${ticker} ${maturity} Gov+ ${govSpread} Z+${zSpread} Yield ${row.original.yield}% ${isin}`;

    if (navigator?.clipboard && navigator.clipboard.writeText) {
      // console.log('first method');
      navigator.clipboard.writeText(formattedValue).then(() => {
        // console.log('Copied to clipboard 1st methos', formattedValue);
        toast.success(text.recordCopied)
      }).catch(err => {
        console.error('Failed to copy:', err);
      });
    } else {
      // console.log('second method');
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = formattedValue;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        document.execCommand('copy');
        // console.log('Copied to clipboard 2nd method:', formattedValue);
        toast.success(text.recordCopied)
      } catch (err) {
        console.error('Failed to copy:', err);
      }
      document.body.removeChild(textArea);
    }
  };

  const sideTypeMap = {
    weBuy: 'We buy',
    weSell: 'We sell',
    clientBuys: 'Client buys',
    clientSells: 'Client sells'
  };

  const bankColumns = [
    {
      id: 'select',
      header: ({ table }) => (
        <div className="">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
      size: 10
    },
    {
      header: "Copy/Duplicate",
      //header: "Copy",
      id: 'copy',
      cell: ({ row }) => (
        <div className="px-1">
          <span className="px-4" style={{ cursor: "copy" }} data-toggle="tooltip" data-placement="top" title="Copy Row">
            <i className="icon-copy fa fa-clone" aria-hidden="true" onClick={() => copyRowValue(row)}></i>
          </span>

          <span className="px-4" style={{ cursor: "pointer" }} data-toggle="tooltip" data-placement="top" title="Duplicate Row">
            <i
              className="fa fa-files-o"
              aria-hidden="true"
              onClick={() => handleDuplicateRow(row)}
            ></i>
          </span>
        </div>
      ),
      //size: 10,
    },
    {
      header: 'Side',
      accessorKey: 'direction',
      id: 'direction',
      cell: ({ row }) => {
        const direction = row.original.direction;
        const displayValue = sideTypeMap[direction] || direction;
        return <span className="">{displayValue}</span>;
      },
    },
    {
      header: 'Ticker',
      accessorKey: 'ticker',
      id: 'ticker',
    },
    {
      header: 'Coupon',
      accessorKey: 'coupon',
      id: 'coupon',
    },
    {
      header: 'Maturity',
      accessorKey: 'maturity',
      id: 'maturity',
    },
    {
      header: 'Bond',
      accessorKey: 'bond',
      id: 'bond',
    },
    {
      header: 'Size',
      accessorKey: 'size',
      id: 'size',
    },
    {
      header: 'Currency',
      accessorKey: 'currency',
      id: 'currency',
    },
    {
      header: 'Isin',
      accessorKey: 'isin',
      id: 'isin',
    },
    {
      header: 'Gov+Spread',
      accessorKey: 'govSpread',
      id: 'govSpread',
    },
    {
      header: 'Z+Spread',
      accessorKey: 'zSpread',
      id: 'zSpread',
    },
    {
      header: 'Yield',
      accessorKey: 'yield',
      id: 'yield',
    },
    {
      header: 'Trader',
      accessorKey: 'trader',
      id: 'trader',
    },
    {
      header: 'Sector',
      accessorKey: 'sector',
      id: 'sector',
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      id: 'comments',
      size: 160
    },
    {
      header: 'Last Update',
      accessorKey: 'updatedAt',
      //id: 'updatedAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.updatedAt, globalContext.timeZoneValue)}
        </div>
      ),
      width: "20px",
    },
    {
      header: 'Created On',
      accessorKey: 'createdAt',
      //id: 'updatedAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
      width: "20px",
    },
    // {
    //   header: '',
    //   id: 'action',
    //   cell: ({ row }) => (
    //     <div className="d-flex justify-content-between">
    //       <i
    //         className="icon-copy fa fa-trash mr-10 cursor bg-custom-eye rounded-circle p-2"
    //         data-toggle="modal" data-target="#alert-modal"
    //         onClick={() => {
    //           setShowDeleteModal(true)
    //           setDeleteId(row.original)
    //         }}
    //       ></i>
    //     </div>
    //   )
    // },
  ];

  const handleDelete = () => {
    api.deactivateAxe({ id: deleteId._id }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getfeedbacks();
        setShowDeleteModal(false)
        setDeleteId(null)
      }
    }).catch((err) => {
      setShowDeleteModal(false);
      setDeleteId(null);
      console.error("Deleting bank error => ", err);
    });
  };

  const addToFeedback = (axesType) => {
    let selectedRows
    if (axesType == 'Axes') {
      selectedRows = tableInstance2 && tableInstance2.getSelectedRowModel().flatRows;
    } else {
      selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;
    }
    if (selectedRows.length > 0) {
      if (axesType == 'DailyTopAxes') {
        setAddToFeedbackBtnStatusDaily(true)
      } else {
        setAddToFeedbackBtnStatusAxes(true)
      }
      const selectedAxesDatas = selectedRows.map(row => row.original);
      let sendObj = {
        selectedAxesDatas: selectedAxesDatas,
        axesType: axesType,
        associatedWith: cookieData.AssociatedWith,
        createdBy: cookieData._id,
      }
      api.addToFeedback(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);

          // Reset selection to default
          tableInstance.resetRowSelection();
          tableInstance2.resetRowSelection();
          if (axesType == 'DailyTopAxes') {
            setAddToFeedbackBtnStatusDaily(false)
          } else {
            setAddToFeedbackBtnStatusAxes(false)
          }
        } else {
          toast.error(res.message);
          // Reset selection to default
          tableInstance.resetRowSelection();
          tableInstance2.resetRowSelection();
          setAddToFeedbackBtnStatusAxes(false)
          setAddToFeedbackBtnStatusDaily(false)
        }
      }).catch((err) => {
        console.error("Add to feedback error => ", err);
        // Reset selection to default
        tableInstance.resetRowSelection();
        tableInstance2.resetRowSelection();
        setAddToFeedbackBtnStatusAxes(false)
        setAddToFeedbackBtnStatusDaily(false)
      });
    }
    tableInstance.resetRowSelection();
  }

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      <div className="mb-10">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h4 className="text-black h3 mb-3">{text.dailyTopAxes}</h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 mb-sm-3">
              <div className="d-flex justify-content-between justify-content-md-end justify-content-md-end">
                {/* {dailyTopAxesData?.length > 0 && ( */}
                <>
                  <i
                    className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                    aria-hidden="true"
                    onClick={handlePopoverClick}
                  ></i>
                  <Overlay
                    show={showPopover}
                    target={target}
                    placement="bottom"
                    containerPadding={20}
                    className="setOverlay"
                    ref={popoverRef}
                  >
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div className="inline-block  rounded">
                          <div className="d-flex align-items-center p-1 left-margin">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="10 0 512 512"
                              width="20"
                              height="20"
                            >
                              <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                            </svg>
                            <span className="ml-2">
                              <b>{text.filterColumn}</b>{" "}
                            </span>
                          </div>
                          <div className="row p-2">
                            {tableInstance &&
                              tableInstance
                                .getAllLeafColumns()
                                .filter(
                                  (column) =>
                                    column.id !== "action" &&
                                    column.id !== "select"
                                )
                                .map((column) => {
                                  return (
                                    <div key={column.id} className="col-6 px-1">
                                      <label className="cursor">
                                        <input
                                          {...{
                                            type: "checkbox",
                                            checked:
                                              checkboxState[column.id] !==
                                                undefined
                                                ? checkboxState[column.id]
                                                : column.getIsVisible(),
                                            onChange:
                                              combinedOnChangeHandler(column,),
                                          }}
                                        />{" "}
                                        {column.columnDef.header}
                                      </label>
                                    </div>
                                  );
                                })}
                          </div>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
                {/*  )} */}
                <div className="d-flex justify-content-end align-items-md-center">
                  <input
                    type="button"
                    value={text.addToFeedback}
                    className="btn btn-orange"
                    onClick={() => addToFeedback('DailyTopAxes')}
                    disabled={addToFeedbackBtnStatusDaily}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>


        {/* for Daily Top axes */}
        <div className="card-box">
          <DataTable data={dailyTopAxesData} columns={bankColumns} setTableInstance={setTableInstance} isLoading={isLoading}
            columnVisibility={columnVisibility}
            setColumnVisibility={setColumnVisibility}
          />
        </div>

        {/* for Axes */}
        <div className="row mt-3">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h4 className="text-black h3 mb-3">{text.axes}</h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="d-flex justify-content-between justify-content-md-end">
              {/* {axesData?.length > 0 && ( */}
              <>
                <i
                  className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                  aria-hidden="true"
                  onClick={handlePopoverClick1}
                ></i>
                <Overlay
                  show={showPopover1}
                  target={target1}
                  placement="bottom"
                  containerPadding={20}
                  className="setOverlay"
                  ref={popoverRef1}
                >
                  <Popover id="popover-basic">
                    <Popover.Body>
                      <div className="inline-block  rounded">
                        <div className="d-flex align-items-center p-1 left-margin">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="10 0 512 512"
                            width="20"
                            height="20"
                          >
                            <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                          </svg>
                          <span className="ml-2">
                            <b>{text.filterColumn}</b>{" "}
                          </span>
                        </div>
                        <div className="row p-2">
                          {tableInstance2 &&
                            tableInstance2
                              .getAllLeafColumns()
                              .filter(
                                (column) =>
                                  column.id !== "action" &&
                                  column.id !== "select"
                              )
                              .map((column) => {
                                return (
                                  <div key={column.id} className="col-6 px-1">
                                    <label className="cursor">
                                      <input
                                        {...{
                                          type: "checkbox",
                                          checked:
                                            checkboxState1[column.id] !==
                                              undefined
                                              ? checkboxState1[column.id]
                                              : column.getIsVisible(),
                                          onChange:
                                            combinedOnChangeHandler1(column),
                                        }}
                                      />{" "}
                                      {column.columnDef.header}
                                    </label>
                                  </div>
                                );
                              })}
                        </div>
                      </div>
                    </Popover.Body>
                  </Popover>
                </Overlay>
              </>
              {/*  )} */}
              <div className="d-flex justify-content-end align-items-md-center">
                <input
                  type="button"
                  value={text.addToFeedback}
                  className="btn btn-orange"
                  onClick={() => addToFeedback('Axes')}
                  disabled={addToFeedbackBtnStatusAxes}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-box">
          <DataTable data={axesData} columns={bankColumns} setTableInstance={setTableInstance2} isLoading={isLoading}
            columnVisibility={columnVisibility1}
            setColumnVisibility={setColumnVisibility1} />
        </div>
      </div>

    </>
  );
};

export default Index;
