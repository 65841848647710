import React, { useContext, useMemo, useRef, useState, useCallback, useEffect } from "react";
import DataTableEditable from "../../../components/DataTable/DataTableEditable";
import AxeApi from "../../../api/axeApi";
import { toast } from "react-toastify";
import { Popover, Overlay } from "react-bootstrap";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import text from '../../../util/text';
import IndeterminateCheckbox from "../../../components/DataTable/IndeterminateCheckbox";
import GlobalContext from "../../../context/global/globalContext";
import { timeZoneChange, sidebarModule } from '../../../util/constants'
import { Cookies } from "react-cookie";
import TextAreaCell from "../../../components/DataTable/TextAreaCell";
import DropdownCell from "../../../components/DataTable/DropdownCell";

import { arrayMove } from '@dnd-kit/sortable';

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const api = new AxeApi();


  const [feedbacks, setFeedbacks] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const [tableKey, setTableKey] = useState(0);
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [isLoading, setIsLoading] = useState(false)
  const [checkboxState, setCheckboxState] = useState({});
  const [dailyTopAxesBtnStatus, setDailyTopAxesBtnStatus] = useState(false);
  const [axesBtnStatus, setAxesBtnStatus] = useState(false);
  const [saveBtnStatus, setSaveBtnStatus] = useState(true);
  const textAreaRef = useRef(null);

  const [newRow, setNewRow] = useState({});

  const [errors, setErrors] = useState({});

  useEffect(() => {
    getfeedbacks();
  }, [])

  useEffect(() => {
    setTableKey(prevKey => prevKey + 1);
  }, [globalContext.timeZoneValue]);

  const popoverRef = useRef(null);

  /* const getfeedbacks = async () => {
    setIsLoading(true);
    let sendObj = {
      associatedWith: cookieData.AssociatedWith,
      currentUserId: cookieData._id
    };
    api.getAxes(sendObj).then((res) => {
      if (res.success) {
        const modifiedData = res.data.map(item => ({
          ...item,
          action: ''
        }));
        setFeedbacks(modifiedData);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Getting feedbacks error => ", err);
      setIsLoading(false);
    });
  } */

  const getfeedbacks = async () => {
    setIsLoading(true)
    let sendObj = {
      associatedWith: cookieData.AssociatedWith,
      currentUserId: cookieData._id
    };
    api.getAxes(sendObj).then((res) => {
      if (res.success) {
        setFeedbacks(res.data);
        setIsLoading(false)
      }
    }).catch((err) => {
      console.error("Getting feedbacks error => ", err);
      setIsLoading(false)
    });
  }

  async function handleDuplicateRow(row) {
    if (!row || !row.original) {
      console.error("Invalid row data");
      return;
    }
  
    try {
      const res = await api.addEditAxe({
        id: row.original._id, // ID of the row to be duplicated
        isDuplicate: true, // Indicate this is a duplication request
        currentUserId: cookieData._id, // current User
      });
  
      if (res.success) {
        // Prepend the duplicated row to the table data
        setFeedbacks((prevData) => [res.data, ...prevData]);
        toast.success("Row duplicated successfully!");
      } else {
        console.error("Error duplicating row:", res.message);
        toast.error(res.message || "Failed to duplicate the row.");
      }
    } catch (error) {
      console.error("Error duplicating row:", error);
      toast.error("Something went wrong while duplicating the row.");
    }
  }
  

  /* async function handleDuplicateRow(row) {
    // Check if the row has valid data
    if (!row || !row.original) {
      console.error("Invalid row data");
      return;
    }
  
    // Extract the original row data
    const newRow = { ...row.original };
  
    // Modify the new row data
    delete newRow._id; // Remove the unique ID
    newRow.title = `${newRow.title} (Copy)`; // Optionally append "(Copy)" to the title
    newRow.createdBy = cookieData._id; // Update the creator ID if applicable
    newRow.trader = cookieData.name; // Update the trader field
    newRow.associatedWith = cookieData.AssociatedWith; // Update associated field
  
    // Send the new row data using `api.addEditAxe`
    try {
      const res = await api.addEditAxe(newRow); // Call the API to save the duplicated row
  
      if (res.success) {
        // Prepend the new row to the table data
        setFeedbacks((prevData) => [res.data, ...prevData]); // Ensure you're using the correct state setter
        toast.success("Row duplicated successfully!"); // Display a success message
      } else {
        console.error("Error duplicating row:", res.message);
        toast.error(res.message || "Failed to duplicate the row."); // Show error toast
      }
    } catch (error) {
      console.error("Error duplicating row:", error);
      toast.error("Something went wrong while duplicating the row."); // Show error toast
    }
  } */
  

  const data = useMemo(() => feedbacks, [feedbacks]);

  const bankColumns = [
    {
      id: 'select',
      header: ({ table }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </span>
      ),
      cell: ({ row }) => (
        <span className="px-1">
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </span>
      ),
      size: 60
    },
    {
      header: "Duplicate",
      //header: "Copy",
      id: "copy",
      accessorKey: "copy",
      cell: ({ row }) => (
        <div className="px-1">
          <span className="px-4" style={{ cursor: "pointer" }} data-toggle="tooltip" data-placement="top" title="Duplicate Row">
            <i
              className="fa fa-files-o"
              aria-hidden="true"
              onClick={() => handleDuplicateRow(row)}
            ></i>
          </span>
        </div>
      ),
      size: 100,
    },
    {
      id: 'axeType',
      header: 'Axe Type',
      accessorKey: 'axeType',
      cell: ({ row }) => (
        <div className="d-flex justify-content-center">
          {row.original.axeType
            ? (row.original.axeType === 'Axes' ? 'Axe' : 'Daily Top Axe')
            : ''}
        </div>
      ),
      width: "80px",
    },
    {
      header: "Side",
      accessorKey: "direction",
      cell: (props) => {
        return <DropdownCell {...props} />;
      },
      enableSorting: false,
      enableColumnFilter: true,
      id: "direction",
      minSize: 155,
    },
    /*  {
       header: 'Side',
       accessorKey: 'direction',
       id: 'direction',
     }, */
    {
      header: 'Ticker',
      accessorKey: 'ticker',
      id: 'ticker',
    },
    {
      header: 'Coupon',
      accessorKey: 'coupon',
      id: 'coupon',
    },
    {
      header: 'Maturity',
      accessorKey: 'maturity',
      id: 'maturity',
    },
    {
      header: 'Bond',
      accessorKey: 'bond',
      id: 'bond',
    },
    {
      header: 'Size',
      accessorKey: 'size',
      id: 'size',
    },
    {
      header: 'Currency',
      accessorKey: 'currency',
      id: 'currency',
    },
    {
      header: 'Isin',
      accessorKey: 'isin',
      id: 'isin',
    },
    {
      header: 'Gov+Spread',
      accessorKey: 'govSpread',
      id: 'govSpread',
    },
    {
      header: 'Z+Spread',
      accessorKey: 'zSpread',
      id: 'zSpread',
    },
    {
      header: 'Yield',
      accessorKey: 'yield',
      id: 'yield',
    },
    {
      id: 'trader',
      header: 'Trader',
      accessorKey: 'trader',
    },
    {
      header: 'Sector',
      accessorKey: 'sector',
      id: 'sector',
    },
    {
      header: 'Comments',
      accessorKey: 'comments',
      cell: (props) => {
        return <TextAreaCell {...props} />
      },
      id: 'comments',
    },
    {
      id: 'updatedAt',
      header: 'Last Update',
      accessorKey: 'updatedAt',
      //id: 'updatedAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.updatedAt, globalContext.timeZoneValue)}
        </div>
      ),
      width: "20px",
    },
    {
      id: 'createdAt',
      header: 'Created On',
      accessorKey: 'createdAt',
      //id: 'updatedAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
      width: "20px",
    },
    {
      id: 'action',
      header: 'Action',
      accessorKey: 'action',
      cell: ({ row }) => (
        <span className='d-flex justify-content-center'>
          <div className="cursor" data-toggle="modal" data-target="#alert-modal"
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteId(row.original);
            }}>
               <span className='circlewith'>
                <i className="icon-copy fa fa-times-circle" aria-hidden="true"></i>
                </span>
            
          </div>
        </span>
      )
    },
  ];

  const handleDelete = () => {
    api.deactivateAxe({ id: deleteId._id }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getfeedbacks();
        setShowDeleteModal(false)
        setDeleteId(null)
      }
    }).catch((err) => {
      setShowDeleteModal(false);
      setDeleteId(null);
      console.error("Deleting bank error => ", err);
    });
  };

  const changeAxeType = (axesType) => {
    const selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;
    if (selectedRows.length > 0) {
      if (axesType == 'DailyTopAxes') {
        setDailyTopAxesBtnStatus(true)
      } else {
        setAxesBtnStatus(true)
      }
      const selectedUserDatas = selectedRows.map(row => row.original);
      let sendObj = {
        selectedUserData: selectedUserDatas,
        axesType: axesType
      }
      api.addToAxesType(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
          // Reset selection to default
          tableInstance.resetRowSelection();
          if (axesType == 'DailyTopAxes') {
            setDailyTopAxesBtnStatus(false)
          } else {
            setAxesBtnStatus(false)
          }
          getfeedbacks();
        } else {
          toast.error(res.message);
          // Reset selection to default
          tableInstance.resetRowSelection();
        }
      }).catch((err) => {
        console.error("Add team manager error => ", err);
        // Reset selection to default
        tableInstance.resetRowSelection();
        setAxesBtnStatus(false)
        setDailyTopAxesBtnStatus(false)
      });
    }
  }

  const updateData = (rowIndex, columnId, value, id) => {
    const updatedRow = { ...feedbacks[rowIndex], [columnId]: value };
    const formData = { ...updatedRow, columnId, value, id }; // Adjust according to your API requirements

    api.addEditAxe(formData).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setFeedbacks((old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return updatedRow;
            }
            return row;
          })
        );
      } else {
        toast.error(res.message);
      }
    }).catch((err) => {
      console.error("Add bank error => ", err);
      toast.error("An error occurred while updating the data");
    });
  };

  /* const handleKeyDown = (e, accessor) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Shift + Enter pressed
        e.preventDefault();
        setNewRow((prevNewRow) => ({
          ...prevNewRow,
          [accessor]: (prevNewRow[accessor] || '')
        }));
        //  setNewRow((prevNewRow) => ({
        //   ...prevNewRow,
        //   [accessor]: (prevNewRow[accessor] || '') + `\n${text.commentsBullets} `
        // })); 
      } else if (accessor) {
        e.preventDefault();
        //handleSaveClick();
      }
    }
  }; */

  const handleKeyDown = (e, accessor) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (e.shiftKey) {
        // Shift + Enter pressed
        setNewRow((prevNewRow) => ({
          ...prevNewRow,
          [accessor]: (prevNewRow[accessor] || '') + `\n`
        }));
      } else if (accessor) {
        // Enter pressed without Shift
        // handleSaveClick();
      }
    }
  };


  const handleSaveClick = () => {
    setSaveBtnStatus(false)
    // Assign cookieData values to newRow
    const updatedNewRow = {
      ...newRow,
      trader: cookieData.name,
      associatedWith: cookieData.AssociatedWith,
      createdBy: cookieData._id
    };

    //console.log("updatedNewRow", updatedNewRow);

    // Check if any other field has a value
    const hasOtherFields = Object.keys(updatedNewRow).some(
      key => !['trader', 'associatedWith', 'createdBy'].includes(key) && updatedNewRow[key]
    );

    if (!hasOtherFields) {
      //toast.error('Please fill in at least one field.');
      return;
    }

    // Validation
    const newErrors = {};
    /* if (!updatedNewRow.name) newErrors.name = 'Name is required';
    if (!updatedNewRow.age) newErrors.age = 'Age is required';
    if (!updatedNewRow.country) newErrors.country = 'Country is required'; */

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      api.addEditAxe(updatedNewRow).then((res) => {
        if (res.success) {
          // Reset the textarea height after submission
          if (textAreaRef.current) {
            textAreaRef.current.style.height = 'auto';
          }
          toast.success(res.message);
          setNewRow({});
          getfeedbacks();
          // navigate("/manageAxe");
          setSaveBtnStatus(true)
        } else {
          toast.error(res.message);
          setErrors({});
          setSaveBtnStatus(true)
        }
      }).catch((err) => {
        console.error("Add bank error => ", err);
        setErrors({});
        setSaveBtnStatus(true)
      });
    }
  };

  const handleChange2 = (e, accessor) => {
    if (!e || !e.target) {
      return;
    }
    setNewRow({
      ...newRow,
      [accessor]: e.target.value,
    });
  };

  const handleKeyDown2 = (e, accessor) => {
    if (e.key === 'Enter' && accessor) {
      handleSaveClick(e, accessor);
    }
  };

  const onBlur = (e, accessor) => {
    if (accessor) {
      handleSaveClick(e, accessor);
    }
  };

  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  const [columnOrder, setColumnOrder] = useState(() => bankColumns.map((c) => c.id));

  // to persists checkbox changes in filters when users log in/log out
  useEffect(() => {
    const storedVisibility = JSON.parse(localStorage.getItem('columnVisibility'));
    let data;
    if (storedVisibility && storedVisibility[cookieData._id]?.axeMgnt) {
      data = storedVisibility[cookieData._id].axeMgnt;

      // synchronize column data
      const updatedData = synchronizeColumnData(data, bankColumns);
      setColumnVisibility(updatedData.visibility);
      setCheckboxState(updatedData.visibility);
      setColumnOrder(updatedData.order);
      // save updated data back to localStorage
      const storedData = JSON.parse(localStorage.getItem('columnVisibility')) || {};
      storedData[cookieData._id].axeMgnt = updatedData;
      localStorage.setItem('columnVisibility', JSON.stringify(storedData));
    } else {
      initializeColumnVisibilityAndOrder();
    }
  }, []);

  const synchronizeColumnData = (data, bankColumns) => {
    const columnIds = bankColumns.map((column) => column.id);
    const updatedVisibility = columnIds.reduce((acc, columnId) => {
      acc[columnId] = data.visibility[columnId] ?? true;
      return acc;
    }, {});

    const updatedOrder = [...data.order];
    columnIds.forEach((columnId) => {
      if (!updatedOrder.includes(columnId)) {
        updatedOrder.push(columnId);
      }
    });    

    return {
      visibility: updatedVisibility,
      order: updatedOrder,
    };
  };

  const initializeColumnVisibilityAndOrder = () => {
    // Generate initial visibility and order
    const initialVisibility = bankColumns.reduce((acc, column) => {
      acc[column.id] = true; // Assuming all columns are initially visible
      return acc;
    }, {});

    const initialOrder = bankColumns.map((column) => column.id); // Get column order

    setColumnVisibility(initialVisibility);
    setCheckboxState(initialVisibility);

    try {
      const key = sidebarModule[0];
      const storedData = JSON.parse(localStorage.getItem(`columnVisibility`)) || {};
      // Update visibility and order for the current module
      const data = {
        ...storedData,
        [cookieData._id]: {
          ...(storedData[cookieData._id] || {}),
          [key]: {
            visibility: initialVisibility,
            order: initialOrder,
          }
        }
      };
      const serializedData = JSON.stringify(data);
      localStorage.setItem('columnVisibility', serializedData);
    } catch (error) {
      console.error('Error serializing column visibility and order:', error);
    }
  };

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      setColumnOrder((prevColumnOrder) => {
        // Find the current indices of the dragged and over columns
        const oldIndex = prevColumnOrder.indexOf(active.id);
        const newIndex = prevColumnOrder.indexOf(over.id);

        // Reorder the columns based on the new indices
        const newOrder = arrayMove(prevColumnOrder, oldIndex, newIndex);

        // Update localStorage with the new column order
        try {
          const key = sidebarModule[0]; // Use the first module as the key
          const storedData = JSON.parse(localStorage.getItem('columnVisibility')) || {};

          // Update the column order in localStorage for the current module
          const updatedData = {
            ...storedData,
            [cookieData._id]: {
              [key]: {
                visibility: storedData[cookieData._id][key]?.visibility || prevColumnOrder, // Preserve visibility if available
                order: newOrder, // Set new column order
              },
            }
          };

          // Save the updated data back to localStorage
          localStorage.setItem('columnVisibility', JSON.stringify(updatedData));
        } catch (error) {
          console.error('Error updating column order in localStorage:', error);
        }

        return newOrder; // Return the updated order for the state
      });
    }
  }

  /* const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked,
      };

      let key = sidebarModule[0]
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [key]: {},
      };

      data[key][column.id] = event.target.checked;
      localStorage.setItem('columnVisibility', JSON.stringify(data));
      return newState;
    });
  }; */

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event); // Call the column's built-in handler
    setCheckboxState((prevState) => {
      const newState = {
        ...prevState,
        [column.id]: event.target.checked, // Update local state for visibility
      };

      const key = sidebarModule[0]; // Current module key
      let data = JSON.parse(localStorage.getItem('columnVisibility')) || {
        [cookieData._id]: {
          [key]: { visibility: {}, order: [] },
        }
      };

      // Ensure the visibility object exists for the key
      data[cookieData._id][key].visibility = {
        ...(data[cookieData._id][key].visibility || {}),
        [column.id]: event.target.checked, // Update visibility for the column
      };

      localStorage.setItem('columnVisibility', JSON.stringify(data)); // Save to localStorage
      return newState; // Update React state
    });
  };



  const columns = useMemo(() => bankColumns, []);

  const adjustHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleInput = (event) => {
    const textarea = event.target;
    adjustHeight(textarea);
    if (textarea.value === '') {
      textarea.style.height = 'auto'; // Reset to normal height when empty
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      <div className="">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h4 className="text-black h3 mb-3">{text.manageAxe}</h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 mb-sm-3">
              <div className="">
                {/* {dailyTopAxesData?.length > 0 && ( */}
                <div className="row justify-content-between">

                  <div className="col-4">

                    <div className="d-flex justify-content-md-center justify-content-lg-end d-md-none d-sm-block">
                      <i
                        className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                        aria-hidden="true"
                        onClick={handlePopoverClick}
                      ></i>
                      <Overlay
                        show={showPopover}
                        target={target}
                        placement="bottom"
                        containerPadding={20}
                        className="setOverlay"
                        ref={popoverRef}
                      >
                        <Popover id="popover-basic">
                          <Popover.Body >
                            <div className="inline-block  rounded">
                              <div className="d-flex align-items-center p-1 left-margin">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                                <span className="ml-2"><b>{text.filterColumn}</b> </span>
                              </div>
                              <div className="row p-2">
                                {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action' && column.id !== 'select').map(column => {
                                  return (
                                    <div key={column.id} className="col-6 px-1">
                                      <label className="cursor">
                                        <input
                                          {...{
                                            type: 'checkbox',
                                            checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                            onChange: combinedOnChangeHandler(column),
                                          }}
                                        />{' '}
                                        {column.columnDef.header}
                                      </label>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </Popover.Body>
                        </Popover>
                      </Overlay>
                    </div>

                  </div>
                  <div className="col-8">
                    <div className="d-flex flex-wrap flex-md-nowrap justify-content-end">
                      <span className="d-none d-md-block">
                        <i
                          className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                          aria-hidden="true"
                          onClick={handlePopoverClick}
                        ></i>
                        <Overlay
                          show={showPopover}
                          target={target}
                          placement="bottom"
                          containerPadding={20}
                          className="setOverlay"
                          ref={popoverRef}
                        >
                          <Popover id="popover-basic">
                            <Popover.Body >
                              <div className="inline-block  rounded">
                                <div className="d-flex align-items-center p-1 left-margin">
                                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                                  <span className="ml-2"><b>{text.filterColumn}</b> </span>
                                </div>
                                <div className="row p-2">
                                  {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action' && column.id !== 'select').map(column => {
                                    return (
                                      <div key={column.id} className="col-6 px-1">
                                        <label className="cursor">
                                          <input
                                            {...{
                                              type: 'checkbox',
                                              checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                              onChange: combinedOnChangeHandler(column),
                                            }}
                                          />{' '}
                                          {column.columnDef.header}
                                        </label>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </span>
                      <span>
                        <input
                          type="button"
                          value={text.addToDailyAxes}
                          className="btn btn-orange mr-sm-2 mr-md-2"
                          onClick={() => changeAxeType('DailyTopAxes')}
                          disabled={dailyTopAxesBtnStatus}
                        />
                      </span>
                      <span>
                        <input
                          type="button"
                          value={text.addToAxesTool}
                          className="btn btn-orange mt-2 mt-sm-0 mt-md-0"
                          onClick={() => changeAxeType('Axes')}
                          disabled={axesBtnStatus}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-box">
          <DataTableEditable key={tableKey} data={data} columns={columns} columnVisibility={columnVisibility} handleKeyDown={handleKeyDown} setColumnVisibility={setColumnVisibility} setTableInstance={setTableInstance} updateData={updateData} isLoading={isLoading} handleSaveClick={handleSaveClick} handleChange2={handleChange2} addingRow={true} newRow={newRow} excCols={['select', 'copy', 'axeType', 'updatedAt', 'createdAt']} handleKeyDown2={handleKeyDown2} onBlur={onBlur} handleInput={handleInput} textAreaRef={textAreaRef} saveBtnStatus={saveBtnStatus} columnOrder={columnOrder} setColumnOrder={setColumnOrder} handleDragEnd={handleDragEnd} />
        </div>
      </div>
    </>
  );
};

export default Index;
