import React, { useState, useEffect, useContext } from "react";
import GlobalState from "../context/global/globalState";
import image from "../util/image";
import { useCookies, Cookies } from "react-cookie";
import AuthContext from "../context/auth/authContext";
import { toast } from 'react-toastify';
import AuthRouter from "./authRouter";
import MasterAdminRouter from "./masterRouter";
import TraderRouter from "./traderRouter";
import SalesRouter from "./salesRouter";
import SyndicateRouter from "./syndicateRouter";
import BankAdminRouter from "./bankAdminRouter";
import { requestForToken, onMessageListener } from '../firebase';

const IndexRouter = () => {
	const cookies = new Cookies();
	const Auth = useContext(AuthContext);
	const [notification, setNotification] = useState({ title: '', body: '' });

	const notify = (title, body) => {
		toast(
			<div>
				<p><b>{title}</b></p>
				{/* <p>{body}</p> */}
			</div>
		);
	};

	useEffect(() => {
		if (notification.title) {
			notify(notification.title, notification.body);
		}
	}, [notification]);

	//requestForToken();

	onMessageListener()
		.then((payload) => {
			console.log("payload=====", payload);
			setNotification({
				title: payload.notification.title,
				body: payload.notification.body,
			});
		})
		.catch((err) => console.log('failed: ', err));

	useEffect(() => {
	}, [Auth.userDetails])

	const checkfunction = () => {
		let data = cookies.get('userInfo');
		if (cookies.get('userInfo')) {
			return data.userRole;
		} else {
			return ""
		}
	}


	return (
		<React.Fragment>
			<GlobalState>
				<div className="pre-loader">
					<div className="pre-loader-box">
						<div className="loader-logo"><img src={image.logo} alt="" /></div>
						<div className='loader-progress' id="progress_div">
							<div className='bar' id='bar1'></div>
						</div>
						<div className='percent' id='percent1'>0%</div>
						<div className="loading-text">
							Loading...
						</div>
					</div>
				</div>
				<div className="bgColor">
					{
						checkfunction() == "user" ? <MasterAdminRouter />
							: checkfunction() == "master" ? <MasterAdminRouter />
								: checkfunction() == "bankAdmin" ? <BankAdminRouter />
									: checkfunction() == "trader" ? <TraderRouter />
										: checkfunction() == "syndicate" ? <SyndicateRouter />
											: checkfunction() == "sales" ? <SalesRouter />
												: <AuthRouter />
					}
				</div>
			</GlobalState>
		</React.Fragment>
	)
}

export default IndexRouter;
