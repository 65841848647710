import React, { useState } from "react";
import AuthContext from "./authContext";
import { useCookies, Cookies } from "react-cookie";

const AuthState = (props) => {

	const cookies = new Cookies();
	const [userDetails, setUserData] = useState("");

	const update = async (type = "login", data = null) => {
		if (type == "login" && data !== null) {

			await cookies.set(
				'token',
				data.token,
				{
					path: '/',
					maxAge: 60 * 60 * 100,
					expires: new Date(Date.now() + 60 * 60 * 100),
				}
			);
			let a = JSON.stringify(data);
			await cookies.set(
				"userInfo",
				a,
				{
					path: '/',
					maxAge: 60 * 60 * 100,
					expires: new Date(Date.now() + 60 * 60 * 100),
				}
			)
			setUserData(data)

		} else {
			await cookies.remove('userInfo', { path: '/' })
			await cookies.remove('token', { path: '/' })
			setUserData({}); // Clear user data
			// return true
		}
	}

	return (
		<AuthContext.Provider value={{
			update,
			userDetails
		}}>
			{props.children}
		</AuthContext.Provider>
	)

}

export default AuthState;