const text = {
  'ok': 'Ok',
  'cancel': 'Cancel',
  save: 'Save',
  backText: 'Back',
  email: 'Email',
  phone: 'Phone',
  'deleteMessage': 'Are you sure you want to delete this record?',
  'deleteHeader': 'Alert',
  'logoutText': 'Do you really want to log out?',
  'logoutHeader': 'LOG OUT!',
  'myprofile': 'My Profile',
  'notification': 'Notifications',
  'manageFeedbacks': 'Manage feedbacks',
  Feedback: 'feedback',
  colleagueFeedback: 'Colleague Feedbacks',
  addFeedback: 'Add feedback',

  // for manage team manager
  'sidebarManageTeamManager': 'Team Members',
  sidebarAssignTeamManager: 'Assign Team',
  sidebarChangePassword: 'Change Password',
  'addManager': 'Add Member',
  addTeam: 'Add in Team',
  'updateManager': 'Update Member',

  // add team manager
  addUpdateTeamHeader: 'Add/Update Team Member',
  addUpdateTeam: 'Add/Update Team',
  placeHolderName: 'Name',

  // for bank module
  bankheader: 'Manage Banks',
  'sidebarManageBank': 'Banks',
  'addBank': 'Add Bank',
  'updateBank': 'Update Bank',
  teamName: 'Enter Team Name',

  // add bank page
  bankName: 'Bank Name',
  bankAdminName: 'Bank Admin',
  addUpdateHeader: 'Add/Update Bank',

  // data table pagination text
  'goToPage': ' Go to page:',
  'pageSize': 'Show',

  // for axes
  addUpdateAxes: 'Add/Update Axes',
  manageAxe: 'Manage Axe',
  webuy: 'We buy',
  wesell: 'We sell',
  clientBuys:'Client buys',
  clientSells:'Client sells',

  //feedback types
  primaryType: 'P',
  axeType:'A',
  generalType:'G',


  // for no data in tables
  noDataTable: 'No Record Found',

  // Change Password
  changePasswordHeader: 'Change Password',

  // Axe Tool
  manageAxeTool: 'Axe Tool',
  addToFeedback: 'Add Feedback',
  dailyTopAxes: 'Daily Top Axes',
  axes: 'Axes',
  dailyTopAxe: 'Daily Top Axe',
  axe: 'Axe',

  // header
  showManager: ' - (Manager)',
  addToDailyAxes: 'Add Daily Top Axe',
  addToAxesTool: 'Add Axe',

  // datatable editable no value
  toBeFilled: 'To be filled out',

  sendEmail: 'Send Email',
  exportExcel: 'Excel Export',
  exportPDF: 'PDF Export',

  profileImageUpdated: 'Profile photo updated successfully.',
  profileImageDeleted: 'Profile photo deleted successfully',

  banAdminRole : 'Bank Admin',

  // popover
  filterColumn: 'Filter Columns',
  recordCopied: 'Record Copied',

  forAssignTeamOnly: 'Select Bank, Role and Manager to Assign the Team',
  commentsBullets: '• ',

  //share feedback listig
  shareFeedbackTitle: 'Shared With',
  shareFeedbackUserList: 'Shared Feedback User List',

  duplicateFeedbackTitle: 'In case a duplicate record is created, you need to update the record  after 1 hour to receive notification.',
}

export default text;