import React from 'react'
import text from '../../util/text'
import { useNavigate } from "react-router-dom";

export const LogoutModal = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="modal fade deleteModal" id="confirmation-modal" tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div className="modal-body text-center">
              <h2 className="logout-logo"></h2>
              <h4 className="padding-top-30 weight-500">{text.logoutHeader}</h4>
              <h6 className="padding-top-30 mb-30 weight-500 uppercase">{text.logoutText}</h6>
            </div>
            <div className="modal-footer text-center pb-3">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{text.cancel}</button>
              <button type="button" className="btn btn-logout" onClick={() => navigate('/logout')} data-dismiss="modal">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
