import React, { useEffect, useState } from 'react';
import FeedbackApi from '../../api/feedbackApi';
import { Cookies } from 'react-cookie';
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import { bankAdminRole, capitalizeFirstLetter } from '../../util/constants'
import text from '../../util/text';

export const ShareFeedbackModal = ({ selectedList, tableInstance, setShowShareFeedbackModal }) => {

  /* console.log("selectedList",selectedList?.shareList?.sharedMembers);
  console.log("tableInstance",tableInstance);
  console.log("setShowShareFeedbackModal",setShowShareFeedbackModal); */
  
  const [memberIds, setMemberIds] = useState([]); // State to store member IDs

  /* useEffect(() => {
    // Extract memberIds from selectedList
    if (selectedList?.shareList?.sharedMembers) {
      const ids = selectedList.shareList.sharedMembers.map(member => member.memberId);
      setMemberIds(ids);
    }
  }, [selectedList]); */

  console.log("selectedList=========",selectedList);
  

  const api = new FeedbackApi();
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [shareUserList, setShareUserList] = useState([]);
  const [selectedColleagues, setSelectedColleagues] = useState([]);
  const [loading, setLoading] = useState(true);
  

  useEffect(() => {


    const fetchColleagues = async () => {
      try {
        const res = await api.shareFeedbackUserList({feedbackId:selectedList._id});
        if (res.success) {
          console.log("res.users----------------",res.data);
          setShareUserList(res.data);
        }
      } catch (err) {
        setLoading(false);
        console.error('Error fetching colleagues: ', err);
      } finally {
        setLoading(false);
      }
    };

    //if(memberIds.length > 0){
      fetchColleagues();
    //}

  }, []);

  const handleCheckboxChange = (colleagueId) => {
    if (selectedColleagues.includes(colleagueId)) {
      setSelectedColleagues(selectedColleagues.filter(id => id !== colleagueId));
    } else {
      setSelectedColleagues([...selectedColleagues, colleagueId]);
    }
  };

  const handleSaveChanges = () => {
    alert("O hello!");
    /* const selectedRows = tableInstance && tableInstance.getSelectedRowModel().flatRows;

    if (selectedRows.length > 0) {
      let sendObj = {
        userId: cookieData._id,
        feedbackList: selectedList,
        userList: selectedColleagues
      };
      api.assFeedbackToColleague(sendObj).then((res) => {
        if (res.success) {
          toast.success(res.message);
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setShowShareFeedbackModal(false);
        } else {
          toast.error(res.message);
          tableInstance.resetRowSelection();
          setSelectedColleagues([]);
          setShowShareFeedbackModal(false);
        }
      }).catch((err) => {
        console.error("Add team manager error => ", err);
        tableInstance.resetRowSelection();
        setShowShareFeedbackModal(false);
      });
    } */
  };

  const resetSelectedColleagues = () => {
    setSelectedColleagues([]);
    //tableInstance.resetRowSelection();
    setShowShareFeedbackModal(false);
  };

/*   const groupedColleagues = colleagueList?.reduce((groups, colleague) => {
    if (!groups[colleague.userRole]) {
      groups[colleague.userRole] = {};
    }
    const managerId = colleague.isManager ? colleague._id : colleague.managerId;
    if (!groups[colleague.userRole][managerId]) {
      groups[colleague.userRole][managerId] = {
        manager: colleague.isManager ? colleague : null,
        subordinates: []
      };
    }
    if (!colleague.isManager) {
      groups[colleague.userRole][managerId].subordinates.push(colleague);
    }
    return groups;
  }, {}); */

  return (
    <div
      className="modal fade deleteModal"
      id="alert-modal34"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="myLargeModalLabel">
              {text.shareFeedbackUserList}
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-hidden="true"
              onClick={resetSelectedColleagues}
            >
              ×
            </button>
          </div>
  
          <div className="modal-body customXscroll">
            {loading ? (
              <Loader /> // Display a loader while fetching data
            ) : shareUserList.length > 0 ? (
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th>Select</th> */}
                    <th>Shared with</th>
                    <th>Dept</th>
                    <th>Shared At</th>
                  </tr>
                </thead>
                <tbody>
                  {shareUserList.map((user) => (
                    <tr key={user.memberId}>
                      {/* <td>
                        <input
                          type="checkbox"
                          checked={selectedColleagues.includes(user.memberId)}
                          onChange={() => handleCheckboxChange(user.memberId)}
                        />
                      </td> */}
                      <td>{user.name}</td>
                      <td>{capitalizeFirstLetter(user.userRole)}</td>
                      <td>{new Date(user.sharedAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No colleagues found to share feedback with.</p>
            )}
          </div>
  
          <div className="modal-footer">
            {/* <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={resetSelectedColleagues}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSaveChanges}
              data-dismiss="modal"
            >
              Save changes
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
  
};