import React, { useState, useEffect, useRef, useContext } from "react";
import text from '../../util/text';
import image from '../../util/image';
import CommonApi from "../../api/commonApi";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/auth/authContext";
import { toast } from "react-toastify";


const Profile = () => {
  let api = new CommonApi();
  const navigate = useNavigate();
  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');
  const [submitBtnStatus, setSubmitBtnStatus] = useState(false)

  const [userData, setUserData] = useState({
    photourl: '',
    name: '',
    email: '',
    phone: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone: '',
  });

  const fileInputRef = useRef(null);
  const login = useContext(AuthContext);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    let sendObj = {
      _id: cookieData._id
    };
    api.getUserInfo(sendObj).then((res) => {
      if (res.success) {
        setUserData(res.data);
      }
    }).catch((err) => {
      console.error("Getting userinfo error => ", err);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    });

    validateField(name, value);
  };

  const validateField = (name, value) => {
    let errorMsg = '';
    switch (name) {
      case 'name':
        errorMsg = value ? '' : 'Name is required';
        break;
      case 'email':
        errorMsg = value && /^\S+@\S+\.\S+$/.test(value) ? '' : 'Invalid email';
        break;
      case 'phone':
        // errorMsg = value && /^[0-9]{10}$/.test(value) ? '' : 'Invalid phone number';
        errorMsg = value ? '' : 'Phone number is required';
        break;
      default:
        break;
    }
    setErrors({
      ...errors,
      [name]: errorMsg
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitBtnStatus(true)

    if (Object.values(errors).some(error => error)) {
      alert('Please fix the errors before submitting');
      return;
    }

    const formData = new FormData();
    formData.append('_id', cookieData._id);
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('phone', userData.phone);

    try {
      const res = await api.updateUserInfo(formData);
      if (res.success) {
        setUserData(res.data);
        toast.success(res.message);
        login.update("login", res.data);
        setSubmitBtnStatus(false)
      }
    } catch (err) {
      console.error("Updating userinfo error => ", err);
      setSubmitBtnStatus(false)
    }
  };

  const handlePhotoChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('_id', cookieData._id);
      formData.append('name', userData.name);
      formData.append('email', userData.email);
      formData.append('phone', userData.phone);
      formData.append('photo', file);

      try {
        const res = await api.updateUserInfo(formData);
        if (res.success) {
          setUserData(res.data);
          toast.success(text.profileImageUpdated);
          login.update("login", res.data);
        }
      } catch (err) {
        console.error("Updating user photo error => ", err);
      }
    }
  };

  const handleDeletePhoto = async () => {
    try {
      setUserData({
        ...userData,
        photourl: ''
      });
      const formData = new FormData();
      formData.append('_id', cookieData._id);
      formData.append('name', userData.name);
      formData.append('email', userData.email);
      formData.append('phone', userData.phone);
      formData.append('isDelete', true);
      const res = await api.updateUserInfo(formData);
      if (res.success) {
        toast.success(text.profileImageDeleted);
        setUserData(res.data);
        login.update("login", res.data);
      }
    } catch (err) {
      console.error("Deleting user photo error => ", err);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="min-height-200px mb-20 profile-section">
        <div className="p-2">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="title">
                <h4 className="text-black h3 mb-3">
                  {text.myprofile}
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div className="px-3 py-5 card-box height-100-p">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mb-30 bg-white">
              <div className="profile-photo1">
                <div className="text-center">
                  <img src={userData.photourl || image.profileDefault} className="avatar-photo rounded-circle" alt="Profile" />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <span className="border border-3 rounded p-2 cursor" onClick={triggerFileInput}>
                  <i className="fa fa-pencil" style={{ color: "#868899" }} />
                </span>
                <span className="border border-3 rounded p-2 cursor" onClick={handleDeletePhoto}>
                  <i className="fa fa-trash-o" style={{ color: "#868899" }}></i>
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={(e) => handlePhotoChange(e)}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 mb-30 bg-white">
              <div className="profile-tab height-100-p">
                <div className="tab height-100-p">
                  <div className="height-100-p">
                    <div className="profile-setting">
                      <form onSubmit={handleSubmit}>
                        <ul className="row">
                          <li className="weight-500 col-md-12">
                            <h4 className="text-black h5">
                              Profile Information
                            </h4>
                            <div className="dropdown-divider"></div>
                            <div
                              className="form-group"
                              style={{ marginTop: "20px" }}
                            >
                              <label className="required mb-1">Name</label>
                              <input
                                className="form-control form-control-lg"
                                type="text"
                                name="name"
                                value={userData.name}
                                onChange={handleChange}
                              />
                              {errors.name && <div className="text-danger">{errors.name}</div>}
                            </div>

                            <div className="form-group">
                              <label className="required mb-1">Email</label>
                              <input
                                className="form-control form-control-lg"
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={handleChange}
                              />
                              {errors.email && <div className="text-danger">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                              <label className="required mb-1">
                                Phone Number
                              </label>
                              <input
                                className="form-control form-control-lg"
                                type="text"
                                name="phone"
                                value={userData.phone}
                                onChange={handleChange}
                                onInput={(e) => e.target.value = e.target.value.replace(/\D/, '')}
                              // minLength={10}
                              // maxLength={10}
                              />
                              {errors.phone && <div className="text-danger">{errors.phone}</div>}
                            </div>
                            <div
                              className="form-group mb-0 text-end mt-4"
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              <input
                                type="submit"
                                value="Save Changes"
                                className="btn btn-orange"
                                disabled={submitBtnStatus}
                              />
                            </div>
                          </li>
                        </ul>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
