import React, { useContext, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import { useEffect } from "react";
import BankApi from "../../../api/bankApi";
import { toast } from "react-toastify";
import { Popover, Overlay } from "react-bootstrap";
import { DeleteModal } from "../../../components/Modal/DeleteModal";
import text from "../../../util/text";
import { Loader } from "../../../components/Loader";
import GlobalContext from "../../../context/global/globalContext";
import { timeZoneChange } from '../../../util/constants'

const Index = () => {
  const globalContext = useContext(GlobalContext);
  const api = new BankApi();
  const navigate = useNavigate();
  const [banks, setBanks] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [tableInstance, setTableInstance] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const popoverRef = useRef(null);
  const [checkboxState, setCheckboxState] = useState({});

  const handleClickOutside = (event) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target)) {
      setShowPopover(false);
    }
  };

  useEffect(() => {
    if (showPopover) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPopover]);

  useEffect(() => {
    getBanks();
    setIsLoading(true)
  }, []);

  const getBanks = async () => {
    api.getBanks().then((res) => {
      if (res.success) {
        setBanks(res.data);
        setIsLoading(false)
      }
    }).catch((err) => {
      console.error("Getting banks error => ", err);
      setIsLoading(false)
    });
  }
  const data = useMemo(() => banks, [banks])

  const bankColumns = [
    {
      header: 'Bank Name',
      accessorKey: 'bankName',
    },
    {
      header: 'Bank Admin',
      accessorKey: 'bankManagerName',
    },
    {
      header: 'E-mail',
      accessorKey: 'email',
    },
    {
      header: 'Phone',
      accessorKey: 'phone',
    },
    {
      header: '#Team Member',
      accessorKey: 'teamMember',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          <Link className="font-weight-bold" to={`/manageTeamMember/${row.original._id}`}>{row.original.teamMember}</Link>
        </div>
      ),
    },
    {
      header: 'Created Date',
      accessorKey: 'createdAt',
      cell: ({ row }) => (
        <div className="d-flex justify-content-left">
          {timeZoneChange(row.original.createdAt, globalContext.timeZoneValue)}
        </div>
      ),
    },
    {
      header: 'Status',
      accessorKey: 'status',
      cell: ({ row }) => (
        <div className="d-flex justify-content-center align-items-center">
          <label className="switch mb-0">
            <input type="checkbox" className="primary" id={`customSwitch${row.id}`}
              checked={row.original.status === 'active'}
              onChange={() => {
                handleActivateDeactivate(row.original._id)
              }}
              value={row.original.status}
              disabled={row.original.status === 'deleted'} />
            <span className="slider round" htmlFor={`customSwitch${row.id}`} data-toggle="tooltip"
              data-placement="top"
              title={row.original.status === 'deleted' ? 'Account is deleted' : ''}></span>
          </label>
        </div>
      ),
    },
    {
      header: 'Action',
      id: 'action',
      cell: ({ row }) => (

        <span className="d-flex justify-content-around align-items-center">
          {
            row.original.status == 'active' && <>
              <div className="circle cursor"
                onClick={() => handleEdit(row.original)}>
                <i className="icon-copy fa fa-pencil plus-icon"></i>
              </div>

              <div className="circle cursor" data-toggle="modal" data-target="#alert-modal"
                onClick={() => {
                  setShowDeleteModal(true)
                  setDeleteId(row.original)
                }}>
                <i
                  className="icon-copy fa fa-trash plus-icon"
                ></i>
              </div>

            </>
          }
        </span>
      ),
    },
  ];

  const handleEdit = (row) => {
    navigate(`/addBank/${row._id}`);
  };

  const handleDelete = () => {
    api.deleteBank({ id: deleteId._id }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getBanks();
        setShowDeleteModal(false)
        setDeleteId(null)
      }
    }).catch((err) => {
      setShowDeleteModal(false);
      setDeleteId(null);
      console.error("Deleting bank error => ", err);
    });
  };

  const handleActivateDeactivate = (rowId) => {
    api.activatDeacivateBank({ id: rowId }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        getBanks();
      }
    }).catch((err) => {
      console.error("Activate/Deactivate bank error => ", err);
    });
  };

  const handlePopoverClick = (event) => {
    setTarget(event.target);
    setShowPopover(!showPopover);
  };

  const combinedOnChangeHandler = (column) => (event) => {
    column.getToggleVisibilityHandler()(event);
    setCheckboxState((prevState) => ({
      ...prevState,
      [column.id]: event.target.checked,
    }));
  };

  return (
    <>
      {showDeleteModal && <DeleteModal handleDelete={handleDelete} />}
      <div className="mb-10">

        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6">
            <h4 className="text-black h3 mb-3">{text.bankheader}</h4>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="mb-3 mb-sm-3">
              <div className="d-flex justify-content-between justify-content-md-end justify-content-md-end">
                {/* {dailyTopAxesData?.length > 0 && ( */}
                <>
                  <i
                    className="icon-copy fa fa-eye-slash pr-20 cursor bg-custom-eye rounded-circle p-2 mr-4"
                    aria-hidden="true"
                    onClick={handlePopoverClick}
                  ></i>
                  <Overlay
                    show={showPopover}
                    target={target}
                    placement="bottom"
                    containerPadding={20}
                    className="setOverlay"
                    ref={popoverRef}
                  >
                    <Popover id="popover-basic">
                      <Popover.Body>
                        <div className="inline-block  rounded">
                          <div className="d-flex align-items-center p-1 left-margin">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="10 0 512 512" width="20" height="20"><path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                            <span className="ml-2"><b>{text.filterColumn}</b> </span>
                          </div>
                          <div className="row p-2">
                            {tableInstance && tableInstance.getAllLeafColumns().filter(column => column.id !== 'action').map(column => {
                              return (
                                <div key={column.id} className="col-6 px-1">
                                  <label className="cursor">
                                    <input
                                      {...{
                                        type: 'checkbox',
                                        checked: checkboxState[column.id] !== undefined ? checkboxState[column.id] : column.getIsVisible(),
                                        onChange: combinedOnChangeHandler(column),
                                      }}
                                    />{' '}
                                    {column.columnDef.header}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </Popover.Body>
                    </Popover>
                  </Overlay>
                </>
                {/*  )} */}
                <div className="d-flex justify-content-end align-items-md-center">
                  <input
                    type="button"
                    value={text.addBank}
                    className="btn btn-orange"
                    onClick={() => navigate("/addBank")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-box">
          <DataTable data={data} columns={bankColumns} columnVisibility={columnVisibility} setColumnVisibility={setColumnVisibility} setTableInstance={setTableInstance} isLoading={isLoading} />
        </div>
      </div >
    </>
  );
};

export default Index;
