import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { InputGroup, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import AuthApi from "../../api/authApi";
import image from "../../util/image";
import AuthContext from "../../context/auth/authContext";
import { requestForToken, onMessageListener } from '../../firebase';

const Login = () => {
  const [loading, setLoading] = useState(false);
  const api = new AuthApi();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    firebaseDeviceToken: "" // Add firebaseDeviceToken to the login data
  });

  const [emailValidate, setIsEmailValidate] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const login = useContext(AuthContext);

  const onChange = (e) => {
    let { name, value } = e.target;
    setLoginData((prevState) => ({
      ...prevState,
      [name]: value
    }));

    // Password validation
    if (name === "password") {
      if (value.length === 0) {
        setPasswordValidate("empty");
      } else {
        setPasswordValidate("valid");
      }
    }
  };

  const checkEmailValidation = () => {
    const email = loginData.email.toLowerCase();
    const isValid = email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    if (isValid) {
      setIsEmailValidate("valid");
      return true;
    } else {
      setIsEmailValidate("notValid");
      return false;
    }
  };

  const checkPasswordValidation = () => {
    const password = loginData.password;
    if (!password) {
      setPasswordValidate("empty");
      return false;
    } else {
      setPasswordValidate("valid");
      return true;
    }
  };

  const submitData = async () => {
    setLoading(true);
    const EmailValidation = checkEmailValidation();
    const PasswordValidation = checkPasswordValidation();

    if (EmailValidation && PasswordValidation) {
      try {
        // Get the Firebase device token
        const firebaseToken = await requestForToken();
        console.log("Firebase Token:", firebaseToken);

        // Update loginData with Firebase token
        const dataToSubmit = {
          ...loginData,
          firebaseDeviceToken: firebaseToken
        };

        const res = await api.Login(dataToSubmit); // Send login request with Firebase token
        if (res.success) {
          // Handle successful login
          setLoading(false);
          login.update("login", res.data);
          // Reload the page after setting cookies and user data
          window.location.reload();
        } else {
          // Handle login error
          setLoading(false);
          toast.error(res.msg);
          setIsEmailValidate("notValid");
        }
      } catch (err) {
        // Handle errors
        setLoading(false);
        console.error("Login error => ", err);
        toast.error("Login failed! Please try again.");
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="main">
        <div className="login-page">
          <div className="login-wrap d-flex align-items-center flex-wrap justify-content-center">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-md-6 col-lg-6 col-xl-7 d-flex justify-content-center justify-content-sm-center justify-content-md-end justify-content-lg-end">
                  <div className="tagline">
                    <span className="liner"></span>

                    <h2 className="d-inline text-light text-wrap">
                      Manage feedback with our software.
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-5">
                  <div className="login-box bg-white box-shadow border-radius-20">
                    <div className="login-title mb-0">
                      <div className="login-logo-container">
                        <Link to={'/login'}>
                          <img src={image.logo} className="login-logo" alt="Logo" />
                        </Link>
                      </div>
                      <div className="welcome-text fw-bold">Welcome Back!</div>
                      <div className="instruction-text">
                        Enter your credentials to login to administrator area.
                      </div>
                    </div>
                    <form action="dashboard">
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Enter email"
                            aria-describedby="basic-addon1"
                            className="group-input-field"
                            onBlur={checkEmailValidation}
                            name="email"
                            onChange={onChange}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button input-group-text"
                          >
                            {emailValidate === "valid" ? (
                              <i
                                className="icon-copy fa fa-check-circle customization"
                                aria-hidden="true"
                              ></i>
                            ) : emailValidate === "notValid" ? (
                              <i
                                className="icon-copy fa fa-close customization"
                                style={{ color: "red" }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                                className="icon-copy fa fa-check-circle customization1"
                                aria-hidden="true"
                              ></i>
                            )}
                          </InputGroup.Text>

                          {/* <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button input-group-text"
                          >
                            {emailValidate === "valid" ? (
                              <i
                                className="icon-copy fa fa-check-circle customization"
                                aria-hidden="true"
                              ></i>
                            ) : (
                              <i
                              className="icon-copy fa fa-check-circle customization1"
                              aria-hidden="true"
                            ></i>
                            )}
                            {emailValidate === "notValid" ? (
                              <i
                                className="icon-copy fa fa-close customization"
                                style={{ color: "red" }}
                                aria-hidden="true"
                              ></i>
                            ) : (
                              ""
                            )}
                          </InputGroup.Text> */}
                        </InputGroup>
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type={!showPassword ? "password" : "text"}
                            placeholder="Password"
                            aria-describedby="basic-addon12"
                            className="group-input-field"
                            name="password"
                            onChange={onChange}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            className="group-input-button input-group-text cursor"
                          >
                            {!showPassword ? (
                              <i
                                className="icon-copy fa fa-eye customization2"
                                onClick={() => setShowPassword(true)}
                                aria-hidden="true"
                              />
                            ) : (
                              <i
                                className="icon-copy fa fa-eye-slash customization2"
                                onClick={() => setShowPassword(false)}
                                aria-hidden="true"
                              />
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                        {passwordValidate === "empty" && (
                          <div style={{ color: "red" }}>
                            Password is required.
                          </div>
                        )}
                        {/* {passwordValidate === "notValid" && (
                          <div style={{ color: "red" }}>
                            Password must be at least 8 characters long.
                          </div>
                        )} */}
                      </div>
                      <div
                        className="form-group form-row align-items-center"
                        style={{ fontSize: "14px" }}
                      >
                        <div className="col-sm-6">
                          <div className="form-check d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input "
                              id="rememberMe"
                            />
                            <label
                              id="chk"
                              className="form-check-label"
                              htmlFor="rememberMe"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-6 text-right">
                          <Link
                            style={{ fontSize: "14px", fontWeight: 500 }}
                            to="/forgot-password"
                            className="forgot-password-link"
                          >
                            Forgot password?
                          </Link>
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          onClick={submitData}
                        >
                          {loading ? <> <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            Loading...</> : "Login"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
