import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useState, useEffect } from 'react'
import text from '../../util/text'
import EditableCell from './EditableCell';
import { Loader } from '../Loader';
import { useLocation } from 'react-router-dom'; // Import useLocation
import {
  DndContext,
  closestCenter,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, horizontalListSortingStrategy, } from '@dnd-kit/sortable';
import { Cookies } from "react-cookie";
// needed for row & cell level scope DnD setup
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const defaultColumn = {
  cell: (props) => {
    return <EditableCell {...props} />
  }
};

const DataTable = ({ key, data, columns, columnVisibility, setColumnVisibility, setTableInstance, updateData, isLoading, handleAddClick, handleSaveClick, handleChange2, addingRow, newRow, excCols, handleKeyDown2, onBlur, handleKeyDown, handleInput, textAreaRef, saveBtnStatus, handlePaste,columnOrder,setColumnOrder ,handleDragEnd}) => {

  const cookies = new Cookies();
  let cookieData = cookies.get('userInfo');

  const inputStyle = {
    width: "150px"
  };
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState('');
  

  const location = useLocation(); // Get the current location

  const table = useReactTable({
    data,
    columns: columns,
    defaultColumn,
    meta: {
      updateData: updateData,
    },
    state: {
      sorting: sorting,
      globalFilter: filtering,
      columnVisibility,
      columnOrder
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
    onColumnVisibilityChange: setColumnVisibility,
    columnResizeMode: 'onChange',
    onColumnOrderChange: setColumnOrder,
  });

  // Use useEffect to pass the table instance to the parent component
  useEffect(() => {
    setTableInstance(table);
  }, [table, setTableInstance,newRow]);

  /* function handleDragEnd(event) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setColumnOrder((columnOrder) => {
        const oldIndex = columnOrder.indexOf(active.id);
        const newIndex = columnOrder.indexOf(over.id);
        return arrayMove(columnOrder, oldIndex, newIndex);
      });
    }
  } */

  const DraggableTableHeader = ({ header }) => {
    const { attributes, isDragging, listeners, setNodeRef, transform } =
      useSortable({
        id: header.column.id,
      });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: 'relative',
      transform: CSS.Translate.toString(transform),
      transition: 'width transform 0.2s ease-in-out',
      whiteSpace: 'nowrap',
      width: header.column.getSize(),
      zIndex: isDragging ? 1 : 0,
      cursor: 'pointer',
      width: header.getSize()
    };
    return (
      <th
        key={header.id}
        colSpan={header.colSpan}
        ref={setNodeRef}
        onClick={header.column.getToggleSortingHandler()}
        style={style}
      >
        {header.isPlaceholder ? null : (
          <div>
            {flexRender(header.column.columnDef.header, header.getContext())}

            {
              { asc: <span className="icon-copy ti-arrow-up ml-2"></span>, desc: <span className="icon-copy ti-arrow-down ml-2"></span> }[
              header.column.getIsSorted() ?? null
              ]
            }
            <i className="icon-copy fa fa-arrows dragBtnHeader" {...attributes} {...listeners} aria-hidden="true" />
          </div>
        )}
        <div className='resizer' onMouseDown={header.getResizeHandler()} onTouchStart={header.getResizeHandler()}></div>
      </th>
    );
  };

  const DragAlongCell = ({ cell }) => {
    const { isDragging, setNodeRef, transform } = useSortable({
      id: cell.column.id,
    });

    const style = {
      opacity: isDragging ? 0.8 : 1,
      position: 'relative',
      transform: CSS.Translate.toString(transform),
      transition: 'width transform 0.2s ease-in-out',
      width: cell.column.getSize(),
      zIndex: isDragging ? 1 : 0,
    };

    return (
      <td style={style} ref={setNodeRef}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  };

  return (
    <>
      <div className='pt-20 px-4'>
        <div className='row mb-3'>
          <div className='col-12'>
            {/* search bar */}
            <input
              type='text'
              className='form-control'
              value={filtering}
              onChange={e => setFiltering(e.target.value)}
              placeholder='Search...'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>

            <>
              <DndContext
                collisionDetection={closestCenter}
                modifiers={[restrictToHorizontalAxis]}
                onDragEnd={handleDragEnd}
              // sensors={sensors}
              >
                <div className='table-responsive'>
                  <table className='table table-custom' style={{ width: table.getTotalSize() }}>
                    <thead className='thead-light'>
                      {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                          <SortableContext
                            items={columnOrder}
                            strategy={horizontalListSortingStrategy}
                          >
                            {
                              headerGroup.headers.map(header => (
                                <DraggableTableHeader key={header.id} header={header} />
                              ))
                            }
                          </SortableContext>
                        </tr>
                      ))}
                    </thead>

                    <tbody>
                      {addingRow && (
                        <tr>

                          {columns.map((column) => (
                            columnVisibility[column.id] ? (

                              <td key={column.accessorKey}>
                                {excCols.includes(column.id) ? (
                                  '' // Render empty cell if column is excluded or hidden
                                ) : column.accessorKey === 'comments' ? (
                                  <div className="d-flex justify-content-center mb-0">
                                    <textarea
                                      ref={textAreaRef}
                                      id="comments"
                                      name="comments"
                                      className="form-control"
                                      value={newRow[column.accessorKey] || ''}
                                      // value={newRow[column.accessorKey] || '• '}
                                      onChange={(e) => handleChange2(e, column.accessorKey)}
                                      onKeyDown={(e) => handleKeyDown(e, column.accessorKey)}
                                      onInput={handleInput}
                                      onPaste={handlePaste}
                                      rows="1"
                                      style={{ resize: 'both' }}
                                    />
                                  </div>
                                ) : column.accessorKey === 'feedbackType' ? ( // Conditional rendering based on route
                                  <select
                                    className="form-control"
                                    name="feedbackType"
                                    value={newRow[column.accessorKey] || ''}
                                    onChange={(e) => handleChange2(e, column.accessorKey)}
                                  >
                                    <option value="" disabled>Select</option>
                                    <option value="Primary">{text.primaryType}</option>
                                    <option value="Axes">{text.axeType}</option>
                                    <option value="General">{text.generalType}</option>
                                  </select>
                                ) : column.accessorKey === 'axeType' ? ( // Conditional rendering based on route
                                  <select
                                    className="form-control"
                                    name="axeType"
                                    value={newRow[column.accessorKey] || ''}
                                    onChange={(e) => handleChange2(e, column.accessorKey)}
                                  >
                                    <option value="" disabled>Select</option>
                                    <option value="DailyTopAxes">{text.dailyTopAxe}</option>
                                    <option value="Axes">{text.axe}</option>
                                  </select>
                                )
                                  : column.accessorKey === 'direction' ? (
                                    <select
                                      className="form-control"
                                      name="direction"
                                      value={newRow[column.accessorKey] || ''}
                                      onChange={(e) => handleChange2(e, column.accessorKey)}
                                    >
                                      <option value="" disabled>Select</option>
                                      <option value="weBuy">{text.webuy}</option>
                                      <option value="weSell">{text.wesell}</option>
                                      <option value="clientBuys">{text.clientBuys}</option>
                                      <option value="clientSells">{text.clientSells}</option>
                                    </select>

                                  ) : column.accessorKey === 'action' ? (
                                    <span className='d-flex justify-content-center'>
                                      <div className="cursor" data-toggle="modal" data-target="#alert-modal"
                                        onClick={saveBtnStatus ? handleSaveClick : null}>
                                          <span className='circlewith'>
                                            <i className="icon-copy fa fa-plus-circle" ></i>
                                          </span>
                                      </div>
                                    </span>

                                  ) : column.accessorKey === 'trader' ? (
                                    <div className="d-flex justify-content-center">
                                      <input
                                        type="text"
                                        value={cookieData.name}
                                        readOnly
                                        className="form-control"
                                      />
                                    </div>
                                  ) : (
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={newRow[column.accessorKey] || ''}
                                      onChange={(e) => handleChange2(e, column.accessorKey)}
                                    />
                                  )}
                              </td>
                            ) : null
                          ))}
                        </tr>
                      )}
                      {isLoading ? (
                        <tr>
                          <td colSpan={columns.length} className="text-center py-5">
                            <Loader />
                          </td>
                        </tr>

                      ) : !isLoading && data.length === 0 ? (
                        <tr>
                          <td colSpan={columns.length} className="text-center py-5">
                            <h5>{text.noDataTable}</h5>
                          </td>
                        </tr>
                      ) : (
                        table.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <SortableContext
                                key={cell.id}
                                items={columnOrder}
                                strategy={horizontalListSortingStrategy}
                              >
                                <DragAlongCell key={cell.id} cell={cell} />
                              </SortableContext>
                            ))}
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </DndContext>
              {/* pagination */}
              <div className='d-flex flex-wrap justify-content-center justify-content-md-end align-content-center mt-3 mb-3'>
                <div className="mr-2 font-weight-bold align-content-center">
                  <span className='mr-2'>Page</span>
                  {table.getState().pagination.pageIndex + 1} of{' '}
                  {table.getPageCount().toLocaleString()}
                </div>
                <div className="ml-2 font-weight-bold">
                  <span>{text.goToPage}</span>
                  <input
                    type="number"
                    min="1"
                    defaultValue={table.getState().pagination.pageIndex + 1}
                    onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      table.setPageIndex(page)
                    }}
                    className="ml-2 border p-1 rounded width-paginatiion-input"
                  />
                </div>
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={e => {
                    table.setPageSize(Number(e.target.value))
                  }}
                  className='ml-2 border rounded mr-2'
                >
                  {[10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {text.pageSize} {pageSize}
                    </option>
                  ))}
                </select>
                <button className='mr-4 no-bg' onClick={() => table.setPageIndex(0)}>{'<-'}</button>
                <button className='no-bg mr-2' disabled={!table.getCanPreviousPage()} onClick={() => table.previousPage()} >{'<'} </button>
                <button className='no-bg' disabled={!table.getCanNextPage()} onClick={() => table.nextPage()}>{'>'}</button>
                <button className='ml-4 no-bg' onClick={() => table.setPageIndex(table.getPageCount() - 1)}>{'->'}</button>
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default DataTable;
